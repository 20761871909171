import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from 'reactstrap'
import Layout from "../components/layout"
import SEO from "../components/seo"

const VisitPage = () => (
  <Layout>
    <SEO title="Visit" />
    <Container>
    <section style={{height: 130, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <h1>What to expect when you visit our church</h1>
    </section>
    <Row>
      <Col className='visit-container' md={{size: 8, offset: 2}}>
        <p>We encourage everyone to come as they are. No fancy suit? That's fine. Have tattoos? That's fine as well. Everyone is different, and we understand that God loves you, faults and all.</p>
        <p>Our service starts at 11:30 every Saturday. You can expect 30 minutes of singing and dancing (we like to have fun). Then read a scripture from the Bible that ties into the sermon. Afterwards our lead Pastor Dr. Lincoln A. Jailal engages with the congregation for about 20 minutes. During this time he gives us a spiritual news update where he ties the current events to Biblical prophecy. Then we have a 45 - 55 minute sermon. Once the sermon is over we sing one more song and our service is over.</p>
        <p>We then provide a small meal and time to connect and get to know everyone.</p>
        <p>Come visit us this weekend. If you have any questions feel free to contact us. There's always a seat for you at Ambassadors For Christ Ministries.</p>
      </Col>
    </Row>
    </Container>
    <Link to='/sermons'>
      <section className='visit-cta'>
        <p>WATCH OUR LATEST SERMON</p>
      </section>
    </Link>
  </Layout>
)

export default VisitPage
