import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import Logo from '../images/AfcmLogo@3x.png'
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';


class Header extends React.Component {
  constructor(props) {
    super(props)
    this.toggle = this.toggle.bind(this)
    this.state = {
      isOpen: false
    }
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  render () {
    return (
      <div className='header-container'>
      <Navbar color="white" id='navbar-internal' light expand="md">
      <Link to='/'><NavbarBrand className='mr-auto nav-logo'><img alt='Ambassadors For Christ Ministries Website' style={{width: 40, height: 40}} src={Logo} /> Ambassadors For Christ Ministries</NavbarBrand></Link>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <Link className='nav-link header-nav-link' to="/">Home</Link>
            </NavItem>
            <NavItem>
              <Link className='nav-link header-nav-link' to="/visit">Visit</Link>
            </NavItem>
            <NavItem>
              <Link className='nav-link header-nav-link' to="/sermons">Sermons</Link>
            </NavItem>
            <NavItem>
              <NavLink className='header-nav-link' href='https://afcm.churchcenter.com/giving'>Donate</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
