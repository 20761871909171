/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { Facebook, Twitter, Tv } from 'react-feather'
import 'bootstrap/dist/css/bootstrap.min.css'

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} />
        <div
          style={{
            margin: `0 auto`,
          }}
        >
          <main className='main'>{children}</main>
        </div>
        <footer style={{
          height: 80,
          paddingLeft: 60,
          paddingRight: 60,
          backgroundColor: '#F2F2F2',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          right: 0,
          bottom: 0,
          left: 0
        }}>
            <div className='footer-text'>© {new Date().getFullYear()}, Ambassadors For Christ Ministries</div>
            <div style={{width: 112, display: 'flex', justifyContent: 'space-between'}}>
              <a className='social-link' href='https://www.facebook.com/afcmonline/'><Facebook /></a>
              <a className='social-link' href='https://www.twitter.com/afcmtweets'><Twitter /></a>
              <a className='social-link' href='https://www.youtube.com/user/AFCMTube'><Tv /></a>
            </div>
          </footer>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
